<template>
  <form class="m-form m-form--fit m-form--label-align-right">
    <div class="m-portlet__body">
      <div class="form-group m-form__group row">
        <div class="col-10 ml-auto">
          <h3 class="m-form__section">Información básica</h3>
        </div>
      </div>
      <div class="form-group m-form__group row">
        <label
          for="example-text-input"
          class="col-2 col-form-label"
        >Nombres</label>
        <div class="col-7">
          <input
            class="form-control m-input"
            :disabled="!authorizedToEdit"
            type="text"
            v-model="data.first_name"
          >
        </div>
      </div>
      <div class="form-group m-form__group row">
        <label
          for="example-text-input"
          class="col-2 col-form-label"
        >Apellidos</label>
        <div class="col-7">
          <input
            class="form-control m-input"
            type="text"
            :disabled="!authorizedToEdit"
            v-model="data.last_name"
          >
        </div>
      </div>
      <div class="form-group m-form__group row">
        <label
          for="example-text-input"
          class="col-2 col-form-label"
        >Email</label>
        <div class="col-7">
          <input
            class="form-control m-input"
            type="email"
            :disabled="!authorizedToEdit"
            v-model="data.email"
          >
        </div>
      </div>
      <div class="form-group m-form__group row">
        <label
          for="example-text-input"
          class="col-2 col-form-label"
        >Fecha de nacimiento</label>
        <div class="col-7">
          <input
            class="form-control m-input"
            type="date"
            :disabled="!authorizedToEdit"
            v-model="data.birth_date"
          >
        </div>
      </div>
      <div class="form-group m-form__group row">
        <label
          for="example-text-input"
          class="col-2 col-form-label"
        >Causal de mora</label>
        <div class="col-7">
          <v2-select
            :options="debtReasonsOptions"
            label="description"
            v-model="data.cause_credit_default"
          />
        </div>
      </div>
      <div class="m-form__seperator m-form__seperator--dashed m-form__seperator--space-2x"></div>
      <div class="form-group m-form__group row">
        <div class="col-10 ml-auto">
          <h3 class="m-form__section">Ubicación</h3>
        </div>
      </div>

      <div class="form-group m-form__group row">
        <label
          for="departamento de residencia"
          class="col-2 col-form-label"
        >Departamento de residencia</label>
        <div class="col-7">
          <v2-select
            :options="statesOptions"
            label="nombre"
            :value="selectedState"
            @input="changeState"
          />
        </div>
      </div>

      <div class="form-group m-form__group row">
        <label
          for="example-text-input"
          class="col-2 col-form-label"
        >Municipio de residencia</label>
        <div class="col-7">
          <v2-select
            :options="citiesOptions"
            label="nombre"
            :value="selectedCity"
            @input="changeCity"
          />
        </div>
      </div>
      <div class="form-group m-form__group row">
        <label
          for="example-text-input"
          class="col-2 col-form-label"
        >Dirección de residencia</label>
        <div class="col-7">
          <input
            class="form-control m-input"
            type="text"
            :disabled="!authorizedToEdit"
            v-model="data.address"
          >
        </div>
      </div>
      <div class="form-group m-form__group row">
        <label
          for="example-text-input"
          class="col-2 col-form-label"
        >Celular (+57)</label>
        <div class="col-7">
          <input
            class="form-control m-input"
            type="text"
            :disabled="!authorizedToEdit"
            v-model="data.celular"
          >
        </div>
      </div>
      <div class="form-group m-form__group row">
        <label
          for="example-text-input"
          class="col-2 col-form-label"
        >Teléfono de residencia (+57)</label>
        <div class="col-7">
          <input
            class="form-control m-input"
            type="text"
            :disabled="!authorizedToEdit"
            v-model="data.phone"
          >
        </div>
      </div>

      <div class="m-form__seperator m-form__seperator--dashed m-form__seperator--space-2x"></div>
      <div class="form-group m-form__group row">
        <div class="col-10 ml-auto">
          <h3 class="m-form__section">Información financiera</h3>
        </div>
      </div>
      <div class="form-group m-form__group row">
        <label
          for="example-text-input"
          class="col-2 col-form-label"
        >Valor ingresos mensuales</label>
        <div class="col-7">
          <input
            class="form-control m-input"
            type="text"
            :disabled="!authorizedToEdit"
            v-model="data.amount_income"
          >
        </div>
      </div>
      <div class="form-group m-form__group row">
        <label
          for="example-text-input"
          class="col-2 col-form-label"
        >Valor de gastos mensuales</label>
        <div class="col-7">
          <input
            class="form-control m-input"
            type="text"
            :disabled="!authorizedToEdit"
            v-model="data.amount_outcome"
          >
        </div>
      </div>

    </div>
    <div v-if="authorizedToEdit"
    class="m-portlet__foot m-portlet__foot--fit">
      <div class="m-form__actions">
        <div class="row">
          <div class="col-2">
          </div>
          <div class="col-7">
            <button
              type="reset"
              class="btn btn-accent m-btn m-btn--air m-btn--custom"
              v-on:click="$emit('save-data', data)"
            >Guardar cambios</button>
          </div>
        </div>
      </div>
    </div>
    <div v-else
    class="m-portlet__foot m-portlet__foot--fit">
      <div class="m-form__actions">
        <div class="row">
          <div class="col-2">
          </div>
          <div class="col-7">
            <button
              type="reset"
              class="btn btn-accent m-btn m-btn--air m-btn--custom"
              v-on:click="sendOTP"
            >Editar información</button>
          </div>
        </div>
      </div>
    </div>
    <OtpModal
      v-if="openModal"
      :openModal="openModal"
      :telefono="generalInfo.celular"
      :correo="generalInfo.email"
      @otp-emition="handleOtpEmition"
      @otp-validation="handleOtpValidation">
    </OtpModal>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="4000">
      {{ text }}
    </v-snackbar>
  </form>
</template>

<script>
import otpService from "@/services/otp.service.js";
import utilsSerive from "@/services/utils.service.js";
import { mapState, mapMutations } from "vuex";
import OtpModal from "../ui/OtpModal.vue";
export default {
  name: 'GeneralInformationUser',
  components: {
    OtpModal
  },
  data() {
    return {
      authorizedToEdit: false,
      snackbar :false,
      snackbarColor: '',
      text: ''
    }
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    statesOptions: {
      type: Array,
      default: () => ([])
    },
    citiesOptions: {
      type: Array,
      default: () => ([])
    },
    debtReasonsOptions: {
      type: Array,
      default: () => ([])
    },

    selectedCity: {
      type: Object,
      default: () => ({})
    },
    selectedState: {
      type: Object,
      default: () => ({})
    },
  },
  computed: {
    ...mapState({
      generalInfo: (state) => state.users.generalInformation,
      openModal: (state) => state.ui.isOpenModalOtp,
    })
  },

  methods: {
    ...mapMutations({
      setOpenModal: "ui/setIsOpenModalOtp",
    }),
    changeState (state) {
      this.$store.commit("users/setState", state.codigo_dane);
    },

    changeCity (city) {
      this.$store.commit("users/setCity", city.id);
    },
    sendOTP() {
      this.setOpenModal(true)
    },
    handleOtpEmition({success,message}) {
      console.log(success);
      if(success) {
        this.snackbarColor = 'success'
      } else {
        this.snackbarColor = 'warning'
      }
      this.text = message
      this.snackbar = true
    },
    handleOtpValidation({success,message}) {
      if (success) {
        this.snackbarColor = 'success'
        this.authorizedToEdit = true
      } else {
        this.snackbarColor = 'error'
      }
      this.text = message
      this.snackbar = true
    }
    // async sendOTP() {
    //   const data = {
    //         Id: 'xxx', // Recuperar la data del external_id
    //         celular: utilsSerive.removeCountryCode(this.generalInfo.celular),
    //         email: this.generalInfo.email,
    //       };
    //   let res
    //   try {
    //     res = await otpService.generateOTP(data);
    //     const isSuccesfull = res.status === 200
    //     if (isSuccesfull) {
    //       otpService.saveIdentifier(res.data.identificador);
    //       this.snackbarColor = 'success'
    //       this.text = 'OTP enviado con exito'
    //     }
    //   } catch (error) {
    //     if (error.response) {
    //       this.snackbarColor = 'error'
    //       this.text = 'Hubo un fallo enviando el OTP'
    //     }
    //   }
    //   this.snackbar = true
    // }
  }
}

</script>


<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>