<template>
  <div class="container">
    <v-dialog v-model="openModal" persistent width="500" class="pa-sm-2">
      <v-card>
        <v-card-title>
          <v-card-title-text>Código temporal enviado</v-card-title-text>
          <v-spacer></v-spacer>
          <v-btn depressed @click="closeModal">
            <span class="material-symbols-outlined">
              close
            </span>
          </v-btn>
        </v-card-title>

        <v-card-text class="font-weight-medium">
          <p>
            Hemos enviado un código temporal al correo
            <span class="font-weight-bold">{{ correoFormateado }}</span> y al teléfono
            <span class="font-weight-bold">{{ telefonoFormateado }}</span
            >, por favor ingrese el código de verificación.
          </p>

          <v-text-field
            v-model="otp"
            color="indigo darken-4"
            label="Ingrese el código de verificación"
            type="text"
            class="w-100"
            :disable="verifying"
            :loading="verifying"
          />

          <v-card-text
            class="py-0 blue lighten-5 pa-sm-2 rounded-lg"
            v-if="!esperando"
          >
            <v-flex class="d-flex align-center justify-space-between">
              <span class="material-symbols-outlined" style="font-size: 35px;">
                autorenew
              </span>
              <span class="text-description"
                >¿No llegó el código temporal? Para reenviarlo haga
                <a
                  class="black--text text-decoration-underline"
                  @click="sendOTP"
                  >clic aquí </a
                >.
              </span>
            </v-flex>
          </v-card-text>

          <v-card-text class="text-center py-0" v-else>
            <p class="text-description">
              Si no recibe su código de verificación, puede volver a intentarlo
              dentro de {{ otpTimer }} segundos.
            </p>
          </v-card-text>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            block
            color="blue"
            elevation="2"
            raised
            rounded
            x-large
            @click="verifyOtp"
          >
            Ingresar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import otpService from "@/services/otp.service.js";
import utilsSerive from "@/services/utils.service.js";
import { mapMutations } from "vuex";
export default {
  name: "OtpModal",
  data() {
    return {
      verifying: false,
      esperando: false,
      otp: "",
      otpTimer: this.waitingResendTime,
      timerId: null,
    };
  },
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    telefono: {
      type: String,
      default: "",
    },
    correo: {
      type: String,
      default: "",
    },
    waitingResendTime: {
      type: Number,
      default: 20,
    },
  },
  computed: {
    phoneOrEmailIsEmpty() {
      return this.telefono === "" || this.correo === "";
    },
    telefonoFormateado() {
      return utilsSerive.formatToHiddenPhone(this.telefono);
    },
    correoFormateado() {
      return utilsSerive.formatToHiddenEmail(this.correo);
    }
  },
  methods: {
    ...mapMutations({
      setOpenModal: "ui/setIsOpenModalOtp",
    }),
    closeModal() {
      this.setOpenModal(false);
    },
    async sendOTP() {
      this.esperando = true;
      let dataEmit = {};
      if (!this.phoneOrEmailIsEmpty) {
        const data = {
          Id: "xxx", // Recuperar la data del external_id
          celular: utilsSerive.removeCountryCode(this.telefono),
          email: this.correo,
        };
        try {
          const res = await otpService.generateOTP(data);
          const isSuccesfull = res.status === 200;
          if (isSuccesfull) {
            otpService.saveIdentifier(res.data.identificador);
            dataEmit.success = true;
            dataEmit.message = "Otp enviado exitosamente";
          }
          if (this.esperando) {
            setTimeout(() => {
              // Después de 20 segundos, si el usuario aún no ha recibido la confirmación, cambia resendOTP a false
              this.esperando = false; // Después de 20 segundos, se puede volver a enviar el OTP
            }, 20000);
            this.startOtpTimer();
          }
        } catch (error) {
          if (error.response) {
            dataEmit.success = false;
            dataEmit.message = error.response.data.mensaje;
          }
        }
      } else {
        dataEmit.success = false;
        dataEmit.message = "El celular y el correo son obligatorios";
      }
      console.log('ha llegado a la linea de emit');
      console.log(dataEmit);
      this.$emit("otp-emition", dataEmit);
    },
    async verifyOtp() {
      const resIdentifier = otpService.getIdentifier();
      if (resIdentifier.exist) {
        this.verifying = true;
        const data = {
          id: "xxx",
          identificador: resIdentifier.msg,
          textoOtp: this.otp,
        };
        let emitData = {};
        try {
          const response = await otpService.validateOtpGeneral(data);
          if (response.status === 200) {
            emitData.success = true,
            emitData.message = "Otp validado correctamente";
          }
        } catch (error) {
          if (error.response) {
            emitData.success = false;
            emitData.message = error.response.data.mensaje;
          }
        }
        this.verifying = false;
        this.$emit("otp-validation", emitData);
        this.closeModal();
      }
    },
    startOtpTimer() {
      this.timerId = setInterval(() => {
        if (this.otpTimer > 0) {
          this.otpTimer--;
        } else {
          this.stopTimer();
        }
      }, 1000);
    },
    stopTimer() {
      if (this.timerId) {
        clearInterval(this.timerId);
        this.timerId = null;
        this.otpTimer = 20;
      }
    },
  },
  mounted() {
    this.sendOTP();
  },
};
</script>
