<template>
<div>
    <AppSubHeader link='perfil' title='Mi perfil' />

    <main class="m-content">
        <div class="row">
            <div class="col-xl-3 col-lg-4">
                <div class="m-portlet m-portlet--full-height  ">
                    <div class="m-portlet__body">
                        <div class="m-card-profile">
                            <div class="m-card-profile__title m--hide">
                                Perfil
                            </div>
                            <div class="m-card-profile__pic">
                                <div class="m-card-profile__pic-wrapper">
                                    <!--<img src="../assets/app/media/img/users/user4.jpg" alt="" />-->
                                </div>
                            </div>
                            <div class="m-card-profile__details">
                                <span class="m-card-profile__name">{{generalInformation.first_name}}</span>
                                <a href="" class="m-card-profile__email m-link">{{generalInformation.email}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-9 col-lg-8">
                <div class="m-portlet m-portlet--full-height m-portlet--tabs  ">
                    <div class="m-portlet__head">
                        <div class="m-portlet__head-tools">
                            <ul class="nav nav-tabs m-tabs m-tabs-line   m-tabs-line--left m-tabs-line--primary" role="tablist">
                                <li class="nav-item m-tabs__item">
                                    <a class="nav-link m-tabs__link active" data-toggle="tab" href="#m_user_profile_tab_1" role="tab">
                                        <i class="flaticon-share m--hide"></i>
                                        Información general
                                    </a>
                                </li>
                                <li class="nav-item m-tabs__item">
                                    <a class="nav-link m-tabs__link" data-toggle="tab" href="#m_user_profile_tab_2" role="tab">
                                        Información financiera
                                    </a>
                                </li>
                                <li class="nav-item m-tabs__item">
                                    <a class="nav-link m-tabs__link" data-toggle="tab" href="#m_user_profile_tab_3" role="tab">
                                        Cambio de contraseña
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="tab-content">
                        <div class="tab-pane active" id="m_user_profile_tab_1">
                            <GeneralInformationUser :data="generalInformation" 
                            v-on:save-data = "saveInformation"  
                            :statesOptions = "statesOptions"
                            :citiesOptions = "citiesOptions"
                            :debtReasonsOptions = "debtReasonsOptions"
                            :selectedCity = "selectedCity"
                            :selectedState = "selectedState"
                             />
                        </div>
                        <div class="tab-pane " id="m_user_profile_tab_2">
                            <FinanceInformationUser :data="financeInformation" v-on:save-data="saveInformation" />
                        </div>
                        <div class="tab-pane " id="m_user_profile_tab_3">
                            <ChangePasswordUser />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
</template>

<script>

import AppSubHeader from "@/components/layout/AppSubHeader.vue";
import GeneralInformationUser from "@/components/user/GeneralInformation.vue";
import FinanceInformationUser from "@/components/user/FinanceInformation.vue";
import ChangePasswordUser from "@/components/user/ChangePassword.vue";

import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name:'UserProfilePage',
  components:{
      AppSubHeader,
      GeneralInformationUser, 
      FinanceInformationUser,
      ChangePasswordUser,
  },
  computed:{
      ...mapState({
          generalInformation : state => state.users.generalInformation,
          financeInformation : state => state.users.financeInformation,
          statesOptions : state => state.parameters.states,
          citiesOptions : state => state.parameters.cities,
          debtReasonsOptions : state => state.parameters.debtReasons,
      }),
    ...mapGetters({
        selectedState: "users/selectedState",
        selectedCity: "users/selectedCity",
    }),
  },
  watch:{
      selectedState(newValue, oldValue){
          if(newValue.codigo_dane && newValue.codigo_dane!=oldValue.codigo_dane){
            this.$store.dispatch("parameters/getCities", newValue.codigo_dane);
          }
      }
  }, 
  methods: {
        saveInformation(){
            let data = {
                generalInformation:this.generalInformation,
                financeInformation:this.financeInformation
                }
            this.$store.dispatch('users/updateProfile', data)
        },
    },
    created(){
        this.$store.dispatch("parameters/getStates");
        this.$store.dispatch("parameters/getDebtReasons");
    },
}

</script>