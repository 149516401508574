<template>
    <form class="m-form m-form--fit m-form--label-align-right" v-on:submit.prevent>
        <div class="m-portlet__body">

            <div class="form-group m-form__group row">
                <label for="example-text-input" class="col-2 col-form-label">Nombre de la empresa donde trabaja</label>
                <div class="col-7">
                    <input class="form-control m-input" type="text" v-model="data.name">
                </div>
            </div>
            <div class="form-group m-form__group row">
                <label for="example-text-input" class="col-2 col-form-label">Teléfono de trabajo</label>
                <div class="col-7">
                    <input class="form-control m-input" type="text" v-model="data.phone">
                </div>
            </div>
            <div class="form-group m-form__group row">
                <label for="example-text-input" class="col-2 col-form-label">Dirección de trabajo</label>
                <div class="col-7">
                    <input class="form-control m-input" type="text" v-model="data.address">
                </div>
            </div>
            <div class="form-group m-form__group row">
                <label for="example-text-input" class="col-2 col-form-label">Email de trabajo</label>
                <div class="col-7">
                    <input class="form-control m-input" type="email" v-model="data.email">
                </div>
            </div>
        </div>
        <div class="m-portlet__foot m-portlet__foot--fit">
            <div class="m-form__actions">
                <div class="row">
                    <div class="col-2">
                    </div>
                    <div class="col-7">
                        <button type="reset" class="btn btn-accent m-btn m-btn--air m-btn--custom" v-on:click="$emit('save-data', data, $event)" >Guardar cambios</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>

export default {
  name:'FinanceInformationUser',
  props:{
      data:{
          type: Object,
        default: () => ({})
      }
  }
}

</script>