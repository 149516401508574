<template>
    <form class="m-form m-form--fit m-form--label-align-right" :v-on:submit.prevent="changePassword">
        <div class="m-portlet__body">

            <div class="form-group m-form__group row">
                <label for="example-text-input" class="col-2 col-form-label">Nueva Contraseña</label>
                <div class="col-7">
                    <input class="form-control m-input" type="password" required v-model="new_password1">
                </div>
            </div>
            <div class="form-group m-form__group row">
                <label for="example-text-input" class="col-2 col-form-label">Confirmar nueva contraseña</label>
                <div class="col-7">
                    <input class="form-control m-input" type="password" required v-model="new_password2">
                </div>
            </div>
        </div>
        <div class="m-portlet__foot m-portlet__foot--fit">
            <div class="m-form__actions">
                <div class="row">
                    <div class="col-2">
                    </div>
                    <div class="col-7">
                        <button type="reset" class="btn btn-accent m-btn m-btn--air m-btn--custom">Cambiar contraseña</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>

export default {
  name:'ChangePasswordUser',
}

</script>


<script>
import { mapGetters } from "vuex";
import ErrorForm from '@/components/ui/ErrorForm.vue';

export default {
    name: "ChangePasswordUser",
    
    components:{
        ErrorForm,
    },

    data(){
        return {
            new_password1: '',
            new_password2: '',
        }
    },
    computed: {
        ...mapGetters({
            msg: "users/firstError",
            activeError : "users/hasErrors",
        }),
    },
    methods:{
        changePassword(){
            let new_password1 = this.new_password1;
            let new_password2 = this.new_password2;
            this.$store.dispatch('users/changePassword', {new_password1, new_password2});
        },
    }
}
</script>